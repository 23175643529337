import {
    maxLength, minLength, required, sameAs
} from 'vuelidate/lib/validators';

export const validationRules = {
    password: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(50),
        async passwordStrengthTest(value) {
            // ignore this validator, if any of the previous fail
            if (!this.$v.password.required || !this.$v.password.minLength || !this.$v.password.maxLength) {
                return true
            }
            const result = await this.validatePassword(value)
            this.$v.password.$params.passwordStrengthTest = result
            return result.value
        }
    },
    repeatPassword: {
        required,
        sameAs: sameAs('password')
    }
}
