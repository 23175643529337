<template>
    <auth-layout>
        <h2
            slot="header"
            class="eister-modal__header-text"
        >
            Reset Password
        </h2>
        <form
            slot="body"
            @submit.prevent="submit"
        >
            <div class="profile-info__form">
                <div class="profile-info__form-group">
                    <div class="profile-info__form-row">
                        <div class="profile-info__col50 profile-info__column">
                            <form-input
                                v-model="$v.password.$model"
                                name="password"
                                legend="Password"
                                prepend-icon="LockIcon"
                                :field.sync="password"
                                :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                                :type="showPassword ? 'text' : 'password'"
                                :errors="errorText || validationMessage($v.password)"
                                :is-valid="$v.password.$dirty && !$v.password.$anyError"
                                @clicked="showPassword = !showPassword"
                                @input.native="inputHandler"
                                @blur.native="$v.password.$touch()"
                            />
                            <progress-linear :validate="$v.password.$params.passwordStrengthTest" />
                        </div>
                        <div
                            class="profile-info__col50"
                            style="margin: 30px 0"
                        >
                            <form-input
                                v-model="$v.repeatPassword.$model"
                                name="password"
                                legend="Password"
                                prepend-icon="LockIcon"
                                :field.sync="repeatPassword"
                                :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                                :type="showPassword ? 'text' : 'password'"
                                :errors="validationMessage($v.repeatPassword)"
                                :is-valid="$v.repeatPassword.$dirty && !$v.repeatPassword.$anyError"
                                @clicked="showPassword = !showPassword"
                                @input.native="$v.repeatPassword.$touch()"
                                @blur.native="$v.repeatPassword.$touch()"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <footer class="profile-info__footer justify-center">
                <button
                    :loading="loading"
                    class="btn-base btn-base_colored btn-base_normal login_button reset_btn"
                    style="width: 100%"
                >
                    <i class="far fa-save" />Submit
                </button>
            </footer>
        </form>
    </auth-layout>
</template>

<script>
// components
import AuthLayout from '@/layouts/AuthLayout'
import ProgressLinear from '@/components/ProgressLinear'

// vuelidate
import { validationMessage } from 'vuelidate-messages'
import { validationMixin } from 'vuelidate'
import passwordValidationMixin from '@/mixins/passwordValidationMixin'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/ResetPassword/Rules'
import { formMessages } from '@/validation/ResetPassword/Messages'

// store
import { createNamespacedHelpers } from 'vuex'
import {
    ACTION_ADMIN_RESET_PASSWORD_SET_NEW,
    ACTION_VALIDATE_RESET_TOKEN
} from '@/store/modules/auth/action-types.js'

const { mapActions: mapAdminActions } = createNamespacedHelpers('auth')

export default {
    name: 'IndexVue',
    components: {
        AuthLayout,
        ProgressLinear
    },
    mixins: [
        validationMixin,
        formValidationMixin,
        passwordValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            showPassword: false,
            email: '',
            loading: false,
            password: '',
            repeatPassword: '',
            errorText: ''
        }
    },
    created() {
        this.validateToken()
    },
    mounted() {
        if (!this.$route.params.token) {
            this.$bus.$emit(
                'showSnackBar',
                'The link has expired, you need to use the reset password form again.',
                'info'
            )
            // this.$router.push({ name: 'forgot-password' })
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapAdminActions([
            ACTION_ADMIN_RESET_PASSWORD_SET_NEW,
            ACTION_VALIDATE_RESET_TOKEN
        ]),
        async validateToken() {
            try {
                const response = await this[ACTION_VALIDATE_RESET_TOKEN]({
                    token: this.$route.params.token
                })
                this.email = response?.data || response
            } catch (e) {
                this.expired = true
                this.$bus.$emit(
                    'showSnackBar',
                    'The link has expired, you need to use the reset password form again.',
                    'info'
                )
                this.$router.push({ name: 'forgot-password' })
            }
        },
        async submit() {
            try {
                this.errorText = ''
                this.loading = true
                await this[ACTION_ADMIN_RESET_PASSWORD_SET_NEW]({
                    token: this.$route.params.token,
                    password: this.password,
                    email: this.email
                })
                this.$bus.$emit('showSnackBar', 'Your password has been changed', 'success')
                this.$router.push({ name: 'login' })
            } catch (e) {
                this.errorText = e.response.data.message
            } finally {
                this.loading = false
            }
        },
        inputHandler() {
            this.errorText = ''
            this.$v.password.$touch()
        }
    }
}
</script>
